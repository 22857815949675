import {
    BudgetYear,
    LineItem,
    finalReportLineItem,
    progressReportLineItem,
    progressReportLineItemBudgets
} from '../../app/models'

import { igamUrl } from '../../app/constants';

interface Payload {
    key: string;
    value?: any
}

export function sendMsgToIGAM(payload: Payload) {
    console.log("message sent", payload)
    window.opener && window.opener.postMessage(payload, igamUrl);
}


export function formatDollarValues(value:number, emptyVal:string ="--"): string {
    const roundedVal= Math.round(value * 100)/100

    if (roundedVal > 0 || roundedVal < 0 ){
        return roundedVal.toLocaleString("en-US", {style: "currency", currency: "USD"}).replace(".00", "")
    } else {
        return emptyVal
    }
}

export function formatPercentageValues(value:number):string {
    if (value){
        return `${parseFloat(value.toFixed(2))}%`
    }
    return "--"
}

export function handleDollarInput(input: string) {
    const replaceNonNum = (input).replaceAll(/[^\d.-]/g, '');
    console.log(Number(replaceNonNum))
    return Number(replaceNonNum)
}

export function handleDate(dateStr: string | null) {
    if(dateStr === null){
        return " "
    }
    return new Date(dateStr).toLocaleDateString('en-us')
}


const blankProgressReportBudgets: progressReportLineItemBudgets = {
    actual: {
        TDE: 0,
        Other: 0
    },
    projected: {
        TDE: 0,
        Other: 0
    },
    nextReport: {
        TDE: 0,
        Other: 0
    }
}

const blankFinalReportBudgets: BudgetYear = {
    TDE: 0,
    Other: 0
}

// takes table line from a budget type (ie revenue) and transforms it into a blank progress report line before 
export function transformBudgetlineToProgressReportLine( items: LineItem[]): progressReportLineItem[] {
    const newItems = items.map( item => (
            {
                lineName: item.lineName,
                reqdLine: item.reqdLine,
                locked: true,
                budgets: blankProgressReportBudgets
            }
        )
    )
    return newItems
}
export function transformBudgetlineToFinalReportLine( items: LineItem[]): finalReportLineItem[] {
    const newItems = items.map( item => (
            {
                lineName: item.lineName,
                reqdLine: item.reqdLine,
                locked: true,
                finalBudget: blankFinalReportBudgets
            }
        )
    )
    return newItems
}

export function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}


