import React, { useState, useRef } from "react";
import {  Box, Button, Card, CardHeader, Input, InputLabel, Stack, Typography, OutlinedInput, CardContent, IconButton, CardActionArea, CardActions, Snackbar } from "@mui/material"
import { 
    migrateUpdateProgressReport, 
    selectProgressReports, 
    setCurrentRequirement, 
    addNewProgressReport,
    addNewProgressReport_migration, 
    setSelectedActualDate, 
    selectActualEndDate,
    migrateRemoveProgressReport
} from "../Common/stateSlices/budgetStateSlice";
import CheckIcon from '@mui/icons-material/Check';
import Alert from '@mui/material/Alert';
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { addMigrationTab, removeMigrationTab } from "../Common/stateSlices/appStateSlice";
import {fetchBlackbaudProgressReportRefAsync, selectBlackbaudRequirementData} from "../Common/stateSlices/bbgDataSlice";
import { tabTypes } from "../../app/models";

export const CreateAProgressReport = (props: {currentProgressReport: number}) => {
    const {currentProgressReport} = props;
    const dispatch = useAppDispatch();


    function handleNewProgressReportId(e){
        dispatch(setCurrentRequirement(e.target.value))
    }

    function handleCreateProgressReport(){
        console.log("SAY SOMETHING")
        dispatch(addNewProgressReport)
    }
    return (
        <Card sx={{padding:"2ch"}}>
            <CardHeader title="Create a New Progress Report"/>
            <Stack gap={2} width={200}>
                <Input type="number" value={currentProgressReport} onChange={handleNewProgressReportId} placeholder="Enter Progress Report ID"/>
                <Button variant="contained" onClick={handleCreateProgressReport} >Create</Button>
            </Stack>
        </Card>
    )
}

const ProgressReportCard = ({prId, i}) => {
    const dispatch = useAppDispatch();
    const valueRef = useRef({value: ''});
    const title = `Progress Report ${i+1}`
    const getActualEndDate = useAppSelector(selectActualEndDate)
    const actualEndDate = getActualEndDate(prId);
    const getRequirementData = useAppSelector(selectBlackbaudRequirementData)
    const requirementData = getRequirementData(prId);
    const requirementExists = requirementData?.status === "loaded"
    const readyToAdd = requirementExists && actualEndDate !== ""


    // function handleFetchPR(){
    //     dispatch(fetchBlackbaudProgressReportRefAsync(prId))
    //     setReportAdded(false)
    // }

    function handleUpdateDate(){
        const actualEndDateString = valueRef.current.value;
        dispatch(setSelectedActualDate({dateString: actualEndDateString, progressReportId: prId}));
    }


    function handleRemoveReport(){
        dispatch(migrateRemoveProgressReport(prId))
        dispatch(removeMigrationTab({tab:`progressReportPage${prId}`}))
    }

    return (
    <Card  sx={{padding:"2ch"}}>
        <CardHeader title={title}/>
        <CardContent>
                <Stack gap={3}>
                <Box>
                    <InputLabel htmlFor="progress-report-id">Progress Report ID</InputLabel>
                    <OutlinedInput 
                        id="progress-report-id"
                        type="number" 
                        disabled
                        value={prId}
                        sx={{marginBottom: 2}}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            dispatch(migrateUpdateProgressReport({originalId:prId, newId: Number(event.target.value) }))
                        }}
                        placeholder="Requirement Id"
                    />
                </Box>
                <Box>
                    <InputLabel>Actual End Date</InputLabel>
                    <OutlinedInput 
                        sx={{ width: '170px', fontWeight: 'bold' }}
                        placeholder="MM/DD/YYYY"
                        defaultValue={actualEndDate}
                        inputRef={valueRef}
                        type="date"
                    />
                    <IconButton color={actualEndDate ? "success" : "primary"}>
                        <CheckIcon onClick={handleUpdateDate}/>
                    </IconButton>
                </Box>
                
                </Stack>
        </CardContent>
        <CardActions sx={{border: "1 solid black"}}>
            <Button variant="contained" onClick={handleRemoveReport}> Remove </Button>
        </CardActions>
    </Card>   )
}

const NewProgressReportCard = () => {
    const dispatch = useAppDispatch();
    const valueRef = useRef({value: ''});
    const title = "Create New Progress Report"
    const [actualEndDate, setActualEndDate] = useState("")
    const [prId, setPrId] = useState(null)
    const getRequirementData = useAppSelector(selectBlackbaudRequirementData)
    const progressReportsArr = useAppSelector(selectProgressReports);
    const requirementData = getRequirementData(prId);
    const requirementExists = requirementData?.status === "loaded"
    const [reportAdded, setReportAdded] = useState(false);
    const readyToAdd = requirementExists && actualEndDate !== ""

    const progressReportAlreadyExists = progressReportsArr.includes(prId)
    
    function handleFetchPR(){
        dispatch(fetchBlackbaudProgressReportRefAsync(prId))
        dispatch(setCurrentRequirement(prId))
    }

    function handleAddDate(){
        const actualEndDateString = valueRef.current.value
        setActualEndDate(actualEndDateString)
    }

    function handleAddReport(){
        setReportAdded(true);
        dispatch(addNewProgressReport_migration({prId, actualEndDate}));
        dispatch(addMigrationTab({tab:`progressReportPage${prId}`, navigate: false}))
        setPrId(null);
        setActualEndDate("");
    }

    return (
    <Card 
        sx={{padding:"2ch", 
            width:"50ch"}}
        
        >
        <CardHeader title={reportAdded? "Progress Report Created!":title}/>
        <CardContent>
        { reportAdded
            ?<Typography variant="body1"> Your progress report has been created! A new tab has been created for it on the navigation bar above. <br/><br/>
                    To get to the progress report page, click the "Go to the next progress report" at the bottom of the page. <br/><br/>
                    To edit the 'Actual' date, or remove this progress report, see the progress report cards above.  
                </Typography>
            :<Stack gap={3}>
                <Box>
                    <InputLabel htmlFor="progress-report-id">Progress Report ID</InputLabel>
                    <OutlinedInput 
                        id="progress-report-id"
                        type="number" 
                        value={prId}
                        sx={{marginBottom: 2}}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setPrId(Number(event.target.value))
                        }}
                        placeholder="Requirement Id"
                    />
                    <br/>
                    {progressReportAlreadyExists 
                        ? <Alert severity="error">Progress Report Already Exists!</Alert>
                        : requirementExists 
                            ? <Alert >Progress Report Found!</Alert>
                            : <Button  variant="contained" disabled={progressReportAlreadyExists} onClick={handleFetchPR}>Check Connection</Button>                  
                }
                </Box>
                <Box>
                    <InputLabel>Actual End Date</InputLabel>
                    <OutlinedInput 
                        sx={{ width: '170px', fontWeight: 'bold' }}
                        placeholder="MM/DD/YYYY"
                        defaultValue={actualEndDate}
                        inputRef={valueRef}
                        type="date"
                        disabled={!requirementExists}
                    />
                    <IconButton color={actualEndDate ? "success" : "primary"}>
                        <CheckIcon onClick={handleAddDate}/>
                    </IconButton>
                </Box>
                
            </Stack>
}
        </CardContent>
        <CardActions sx={{border: "1 solid black"}}>
        {reportAdded
        ? <Button variant="contained" onClick={()=>setReportAdded(false)}>Add Progress Report</Button>
        :<Button variant="contained" disabled = {!readyToAdd} onClick={handleAddReport}> Create Progress Report </Button>
    }
         
        </CardActions>
        {/* <Snackbar 
            open={reportAdded} 
            autoHideDuration={2000} 
            onClose={()=> {setReportAdded(false)}}
            message="Progress Report Added!"
        /> */}
    </Card>   )
}



export const MigrateConnectProgressReports = (props) => {
    const dispatch = useAppDispatch();
    const progressReportsArr = useAppSelector(selectProgressReports)

    const handleAddSubmit = () => {
        dispatch(addMigrationTab({tab: tabTypes.submitBudgets , navigate: true}))
    }

    return (
        <>
            <Typography variant="h3" fontSize="3ch"> Connect Progress Reports</Typography>
            <Typography paddingTop={3} fontStyle="italic"> Please fill out the following for each Progress Report: </Typography>
            <Stack paddingLeft={2}>
                <Typography fontStyle="italic">Add the Requirement ID from Blackbaud to the "Progress Report ID" field below, and select "Check Connection". This will make sure the Progress Report can be found in Blackbaud. </Typography>
                <Typography fontStyle="italic">Select the Actual End date, and click the check mark next to it. The check mark will turn green when it is ready.</Typography>
                <Typography fontStyle="italic">Finally, click the "ADD PROGRESS REPORT" button and you will see a new Progress Report tab appear across the top navigation bar.</Typography>
                <Typography fontStyle="italic">Please add the progress reports in order!</Typography>
            </Stack>
            <Stack marginY="3ch" width="50ch" gap={3} >
                
                {progressReportsArr.length > 0 && <Typography variant="h3" fontSize="2.5ch"> Progress Reports:</Typography>}
                {
                    progressReportsArr.map( (progressReportId, i) => <ProgressReportCard key={progressReportId} prId={progressReportId} i = {i}/>) 
                }
                {/* <CreateAProgressReport currentProgressReport={currentProgressReport}/> */}
            </Stack>
            <Typography variant="h3" fontSize="2.5ch"> Create New Progress Report:</Typography>
            <Stack paddingLeft={2}>
                <Typography fontStyle="italic">Enter information below for any new progress reports that weren't migrated, or if creating a budget from scratch.</Typography>
                <Typography fontStyle="italic">Clicking 'Create Progress Report' will add the report to the list above and create the appropriate tab.</Typography>
                <Typography fontStyle="italic">Please add the progress reports in order!</Typography>
            </Stack>
            <NewProgressReportCard />

            <Box marginTop={5} gap={1} display="flex" width={"100%"} justifyContent={"flex-end"}>
                    <Button variant="contained" >Go to the next Progress Report</Button>
                    <Button variant="contained" onClick={handleAddSubmit}>Skip to Submit Page</Button>
            </Box>
        </>
    )


}