import { type } from "os";
import { ReactElement } from "react";
import { EnumType } from "typescript";
import { budgetTypes } from "./budgetTypes";



export enum BudgetID {
    revenue = 'revenue',
    programExpense = 'programExpense',
    indirectExpense = 'indirectExpense',
    endowedFunds = 'endowedFund',
    inKindRevenue = "inKindRevenue",
    inKindExpense = "inKindExpense"
}

export enum ExpenseTypes {
    direct = BudgetID.programExpense,
    indirect = BudgetID.indirectExpense
}

export enum DollarType {
    dollars = 'dollars',
    TDE = "TDE",
    Other = "Other"
}

export enum stateSliceTypes {
    APPLICATIONCONNECTOR = 'applicationConnector',
    REQUIREMENTSCONNECTOR = 'requirementsConnector',
    APPLICATIONADMIN = 'applicationAdmin',
    BUDGETSTATE = 'budgetState',
    BBGSTATE = "bbgDataState"
}

export type RequirementTypes = "Progress Report" | "Final Report"


export const DOLLARS: string = 'dollars';
export const INKIND: string = 'inKind';

export enum tabTypes { start = "start", bbgId = "bbgId", budget = "budget", progressReports= "progressReports", submitBudgets = "submitBudgets"}

export type BudgetTypeExpenses = BudgetID.programExpense | BudgetID.endowedFunds | BudgetID.indirectExpense;
export type SavingStates = "idle" | "saving" | "failed" | "saved";
export type LoadingStates = "idle" | "loading" | "failed" | "loaded" | "new";
export type BlackbaudRequestRef = "idle" | "searching" | "not_found"| "too_many" | "found";
export type BlackbaudRequirementRef = "idle" | "searching" | "not_found" | "found";

export interface revenue {
    label: string;
}

export interface BudgetYear{
    [DollarType.dollars]?: number,
    [DollarType.TDE]?: number,
    [DollarType.Other]?: number,
}

export interface BudgetYears{
    [year: number]: BudgetYear
}

export interface LineItem{
    lineName?: string,
    reqdLine?: boolean,
    toolTip?: string,
    TDEOnly?: boolean,
    OtherOnly?: boolean,
    applicantContribution?: boolean,
    years?: BudgetYears
}

export interface BudgetCategory {
    [category: string]: LineItem[]
}

export interface ProgressReport {
    year: number,
}

export interface BudgetType {
    id: BudgetID,
    name: string,
    groupName: string,
    altGroupName?: string,
    maxItems: number
}

// interfaces for admin

export interface BlackbaudInfo {
    id: string,
    contactName: string,
    applicationStatus: string,
    organizationName: string,
    projectStartDate: Date,
    projectEndDate: Date,
    staffName: string,
    typeDescription: string

}

// interfaces for ProgressReport

export interface UpdatePeriods {
    BeginningDate: string,
    EndingDate: string
}

export interface UpdateCategories {
    
}

export interface UpdateCol {
    lineName?: string,
    reqdLine?: boolean,
    applicantContribution?: boolean,
    categories?: UpdateCategories
}

export interface UpdateCols {
    ActualYTD: UpdateCol,
    ProjectedYearEnd: UpdateCol,
    NextYearsBudget: UpdateCol
}


export interface ProgressReport {
    year: number,
    ActualYTD: UpdatePeriods,
    Projected: UpdatePeriods,
    NextYear: UpdatePeriods,
    BudgetUpdates: UpdateCols
}

export interface AvailableBudgetOptions {
    hasProgramExpenses: boolean,
    hasIndirectExpenses: boolean,
    hasEndowedFunds: boolean
}

export interface progressReportLineItemBudgets {
    actual: BudgetYear,
    projected: BudgetYear,
    nextReport: BudgetYear
}

export interface progressReportLineItem {
    lineName: string,
    reqdLine: boolean,
    locked: boolean,
    carryover?: boolean,
    budgets: progressReportLineItemBudgets
}

export interface finalReportLineItem {
    lineName: string,
    reqdLine: boolean,
    locked: boolean,
    finalBudget: BudgetYear
}

export interface progressReportBudget{
    [progressReportID: string]: {
        reportingPeriodNarrative: string;
        actualEndDate: string;
        [BudgetID.revenue]: progressReportLineItem[];
        [BudgetID.programExpense]: progressReportLineItem[];
        [BudgetID.indirectExpense]?: progressReportLineItem[]; 
    }
}

export interface finalReportBudget{
    created: boolean;
    [BudgetID.revenue]: finalReportLineItem[];
    [BudgetID.programExpense]: finalReportLineItem[];
    [BudgetID.indirectExpense]?: finalReportLineItem[];
}

export enum dateTerms{
    beginning = "beginning",
    ending = "ending",
    nextEndDate = "nextEndDate"
}

export interface progressReportDatePairs{
    [dateTerms.beginning]?: Date,
    [dateTerms.ending]?: Date,
    [dateTerms.nextEndDate]?: Date
}

export function AvailableBudgetTypes({ hasProgramExpenses, hasIndirectExpenses, hasInKindExpenses = false } ) {
    const budgetCategories: BudgetType[] = [
            {
            id: BudgetID.revenue,
            name: "Revenue Source",
            groupName: "Revenue",
            maxItems: 11
            },
        hasInKindExpenses
            ? {
                id: BudgetID.inKindExpense,
                name: "Expense In-Kind",
                groupName: "Expenses (In-Kind)",
                maxItems: 15
            }
            : null,
        hasProgramExpenses
            ? {
                id: BudgetID.programExpense,
                name: "Expense",
                groupName: "Expenses",
                altGroupName: "Direct Expenses",
                maxItems: 15
            }
            : null,
        hasIndirectExpenses
            ? {
                id: BudgetID.indirectExpense,
                name: "Indirect Expenses",
                groupName: "Indirect Expenses",
                maxItems: 15
            }
            : null
    ];
    return budgetCategories.filter( category => category !== null)

}

export interface Payment {
    amount: number,
    scheduleDate: Date
}

interface SharedApplicationStates{
    projectTitle?: string;
    budgetType?: budgetTypes;
    totalYears?: number;
    yearsIGAM?: number;
    budgetNarrative?: string;
    hasProgramExpenses: boolean;
    hasIndirectExpenses: boolean
    hasInKind: boolean;
    loading: LoadingStates;
    saving: SavingStates;
    budgetItems: BudgetCategory;
}

export interface iSharedBudgetStates{
    id?: string;
    budgetKey?: string;
    projectTitle?: string;
    budgetType?: budgetTypes;
    totalYears?: number;
    yearsIGAM?: number;
    budgetNarrative?: string;
    hasProgramExpenses: boolean;
    hasIndirectExpenses: boolean
    budgetLoading: LoadingStates;
    saving: SavingStates;
    budgetItems: BudgetCategory;
    currentProgressReport?: number | null;
    progressReports: number[];
    progressReportBudgets?: progressReportBudget;
    finalReport: number;
    finalReportBudget?: any;
    notFound?: boolean;

}

export interface iAppStates{
    disableSubmit: boolean;
    narrativeOpen: boolean;
    requirementSuccessMsg: boolean;
    printScreenOpen?: boolean;
    printScreenId?: number | null;
    printScreenReqType?: RequirementTypes | null;
    definitionHelpers: {
        visible?: boolean,
        key?: string
    },
    applicationSuccessMsg?: boolean,
    migrationTabs?: string[],
    migrationCurrentTab?: number

}

interface requirementData{
    status?: "failed" | "loading" | "loaded",
    dates?: progressReportDatePairs
}

interface requirementsData{
    [reqId: number]: requirementData
}

export interface iBbgDataState{
    hasBbgRequestRef: BlackbaudRequestRef;
    requestId: number | null;
    bbgRequestRef?: any;
    bbgProgressReportData?: requirementsData,
    bbgFinalReportData?: requirementData,
    hasBbgRequirementRef: BlackbaudRequirementRef;
    requirementSuccessMsg?: boolean;
    payments: Payment[];
    paymentsLoading: "idle" | "loading" | "failed" | "loaded";
}


export interface ApplicationConnectorState extends SharedApplicationStates {
    budgetKey?: string;
    definitionHelpers: {
        visible?: boolean,
        key?: string
    },
    applicationSuccessMsg?: boolean
}

export interface ApplicationAdminState extends RequirementsConnectorState {
    saveStatus: "idle" | "saving" | "failed" | "saved";
    // hasBlackbaudRequestRef: BlackbaudRequestRef;
    // blackbaudRequestRef?: any;
    notFound?: boolean;
    blackbaudInfo?: BlackbaudInfo;
    id?: string;
}

interface Requirements extends SharedApplicationStates {
    id?: string,
    currentProgressReport?: number | null;
    budgetType?: budgetTypes | null;
    budgetKey?: string;
    hasProgramExpenses: boolean;
    hasCapitalExpenses: boolean;
    loading: "idle" | "loading" | "failed" | "loaded";
    saving: "idle" | "saving" | "failed" | "saved";
    budgetItems: BudgetCategory;
    progressReports: number[];
    finalReport: number;
    progressReportBudgets?: progressReportBudget;
    finalReportBudget?: any;
    payments: Payment[];
    disableSubmit: boolean;
}

export interface RequirementsConnectorState extends Requirements {
    definitionHelpers?: {
        visible?: boolean,
        key?: string
    };
    narrativeOpen: boolean,
    budgetPortalData?: any;
    hasBbgRequestRef: BlackbaudRequestRef;
    bbgRequestRef?: any;
    bbgProgressReportData?: {
        [reqId: number]: {
            status?: "failed" | "loading" | "loaded",
            dates?: progressReportDatePairs
            // Next?: progressReportDatePairs
        }
    },
    hasBbgRequirementRef: BlackbaudRequirementRef;
    requirementSuccessMsg?: boolean;
    paymentsLoading: "idle" | "loading" | "failed" | "loaded";
    printScreenOpen?: boolean;
    printScreenId?: number | null;
    printScreenReqType?: RequirementTypes | null
}


export const ExpenseOptions = {
    [BudgetID.programExpense] :[
        "Administrative",
        "Benefits(fringe & required)",
        "Building / Equipment Maintenance",
        "Consultants",
        "Continuing Education",
        "Contract Services",
        "Equipment",
        "Hardware",
        "Insurance",
        "Interns",
        "IT support",
        "Meals",
        "Rent",
        "Research",
        "Salaries",
        "Software Systems",
        "Supplies",
        "Technology",
        "Training",
        "Transportation",
        "Travel",
        "Tuition",
        "Utilities"
    ]
}



export enum yearlyUpdateId{
    current = "current",
    actual = "actual",
    projected = "projected",
    nextReport = "nextReport"
}

interface yearlyUpdates {
    categoryId: yearlyUpdateId,
    categoryName: string
}



export const yearlyUpdateCategories: yearlyUpdates[]  = [
    {
        categoryId: yearlyUpdateId.current,
        categoryName: "Current Budget",
    },
    {
        categoryId: yearlyUpdateId.actual,
        categoryName: "Actual Reporting"
    },
    {
        categoryId: yearlyUpdateId.projected,
        categoryName: "Projected Reporting"
    },
    {
        categoryId: yearlyUpdateId.nextReport,
        categoryName: "Next Period Reporting"
    }
]

export enum FaqType {
    budget = "budget",
    requirement = "requirement",
    admin = "admin"
}
