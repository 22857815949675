import React, { useState, useEffect }  from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Alert } from '@mui/material';

import { useAppSelector } from "../../app/hooks";
import {
  DollarType,
  BudgetID, 
  yearlyUpdateCategories, //list categoryId for budgetItemsCategoryTotal
  yearlyUpdateId,
  dateTerms
} from "../../app/models";

import { 
  selectProgressReportItemsCategorySubTotal,
  selectCurrentProgressReport,
  selectProgressReportItemsRevenueSubTotal2,
  selectProgressReportItemsRevenueTotal2,
  selectProgressReportItemsCategoryNetSubTotal,
  selectProgressReportItemsCategoryNetTotal,
  selectPreviousProgressReportId,
  selectIsFirstProgressReport,
  selectProgressReportCarryForwardValue,
  selectProgressReportItemsExpensesTotal,
  selectProgressReportItemsExpensesSubTotal,

} 
from "../Common/stateSlices/budgetStateSlice";

import { formatDollarValues } from "../Common/helpers";
import { TableRowCat } from "../Common/ThemedMuiComponents";
import { selectApplicablePaymentsAmount, selectBeginingOrEndingDateString } from "../Common/stateSlices/bbgDataSlice";

interface warningItem {
  categoryName?: string, 
  netAmount?:number, 
  carryOver?:number, 
  totalRevenue?:number
}

interface submitReportingCategories {
  actual: number,
  projected: number,
  nextReport: number 
}
let warningListCopy : warningItem[]= [];

const currentAndFutureYearlyCats = yearlyUpdateCategories
  .filter(e => e.categoryId !== yearlyUpdateId.current)

export const ProgressReportSubmit = () => {
  const progressReportId = useAppSelector(selectCurrentProgressReport);
  const isFirstProgressReportSelector = useAppSelector(selectIsFirstProgressReport);
  const isFirstProgressReport = isFirstProgressReportSelector(progressReportId);
  const previousProgressReportId = useAppSelector(selectPreviousProgressReportId);
  const previousReport = previousProgressReportId(progressReportId)
  const progressReportItemsCategorySubTotal = useAppSelector(selectProgressReportItemsCategorySubTotal);
  const progressReportItemsCategoryNetSubTotal = useAppSelector(selectProgressReportItemsCategoryNetSubTotal);
  const progressReportItemsRevenueTotal = useAppSelector(selectProgressReportItemsRevenueTotal2);
  const progressReportItemsRevenueSubTotal = useAppSelector(selectProgressReportItemsRevenueSubTotal2);
  const paymentAmount = useAppSelector(selectApplicablePaymentsAmount);
  const beginningOrEndingDateString = useAppSelector(selectBeginingOrEndingDateString);
  const progressReportCarryForwardValue = useAppSelector(selectProgressReportCarryForwardValue);
  const progressReportItemsExpensesTotal = useAppSelector(selectProgressReportItemsExpensesTotal);
  const progressReportItemsExpensesSubTotal = useAppSelector(selectProgressReportItemsExpensesSubTotal);
  const progressReportItemsCategoryNetTotal = useAppSelector(selectProgressReportItemsCategoryNetTotal);
  const beginningDate = new Date(beginningOrEndingDateString(progressReportId, yearlyUpdateId.nextReport, dateTerms.beginning))
  const endingDate = new Date(beginningOrEndingDateString(progressReportId, yearlyUpdateId.nextReport, dateTerms.ending))
 
  // Revenue Information
  const revenueTotalsRowData = {
    actual: progressReportItemsRevenueTotal(progressReportId, yearlyUpdateId.actual),
    projected: progressReportItemsRevenueTotal(progressReportId, yearlyUpdateId.projected),
    nextReport: progressReportItemsRevenueTotal(progressReportId, yearlyUpdateId.nextReport)
  }
  const revenueDukeRowData={
    actual: progressReportItemsRevenueSubTotal(progressReportId, yearlyUpdateId.actual, DollarType.TDE),
    projected: progressReportItemsRevenueSubTotal(progressReportId, yearlyUpdateId.projected, DollarType.TDE),
    nextReport: paymentAmount(beginningDate, endingDate)
  }
  const revenueOtherRowData={
    actual: progressReportItemsRevenueSubTotal(progressReportId, yearlyUpdateId.actual, DollarType.Other),
    projected: progressReportItemsRevenueSubTotal(progressReportId, yearlyUpdateId.projected, DollarType.Other),
    nextReport: progressReportItemsRevenueSubTotal(progressReportId, yearlyUpdateId.nextReport, DollarType.Other)
  }

  const carryOverPriorYearTde = isFirstProgressReport ? 0 : progressReportCarryForwardValue(previousReport, DollarType.TDE)
  const carryOverPriorYearOther = isFirstProgressReport ? 0 : progressReportCarryForwardValue(previousReport, DollarType.Other)
  const carryOverThisYearTde = progressReportCarryForwardValue(progressReportId, DollarType.TDE)
  const carryOverThisYearOther = progressReportCarryForwardValue(progressReportId, DollarType.Other)
  const revenueCarryforwardRowData = {
    actual: carryOverPriorYearTde + carryOverPriorYearOther,
    projected: carryOverPriorYearTde + carryOverPriorYearOther,
    nextReport: carryOverThisYearTde + carryOverThisYearOther
  }

  // Expense Information
  const expensesTotalsRowData = {
    actual: progressReportItemsExpensesTotal(progressReportId, yearlyUpdateId.actual),
    projected: progressReportItemsExpensesTotal(progressReportId, yearlyUpdateId.projected),
    nextReport: progressReportItemsExpensesTotal(progressReportId, yearlyUpdateId.nextReport)
  }
  const expensesDukeRowData = {
    actual: progressReportItemsExpensesSubTotal(progressReportId, yearlyUpdateId.actual, DollarType.TDE),
    projected: progressReportItemsExpensesSubTotal(progressReportId, yearlyUpdateId.projected, DollarType.TDE),
    nextReport: progressReportItemsExpensesSubTotal(progressReportId, yearlyUpdateId.nextReport, DollarType.TDE)  
  }
  const expensesOtherRowData = {
    actual: progressReportItemsExpensesSubTotal(progressReportId, yearlyUpdateId.actual, DollarType.Other),
    projected: progressReportItemsExpensesSubTotal(progressReportId, yearlyUpdateId.projected, DollarType.Other),
    nextReport: progressReportItemsExpensesSubTotal(progressReportId, yearlyUpdateId.nextReport, DollarType.Other)  
  }

  // Net Deficit Information
  const netTotalsRowData = {
    actual: progressReportItemsCategoryNetTotal(progressReportId, yearlyUpdateId.actual),
    projected: progressReportItemsCategoryNetTotal(progressReportId, yearlyUpdateId.projected),
    nextReport: progressReportItemsCategoryNetTotal(progressReportId, yearlyUpdateId.nextReport)
  }
  
  const netDukeRowData={
    actual: progressReportItemsCategoryNetSubTotal(progressReportId, yearlyUpdateId.actual, DollarType.TDE),
    projected: progressReportItemsCategoryNetSubTotal(progressReportId, yearlyUpdateId.projected, DollarType.TDE),
    nextReport: progressReportItemsCategoryNetSubTotal(progressReportId, yearlyUpdateId.nextReport, DollarType.TDE)
  }
  const netOtherRowData={
    actual: progressReportItemsCategoryNetSubTotal(progressReportId, yearlyUpdateId.actual, DollarType.Other),
    projected: progressReportItemsCategoryNetSubTotal(progressReportId, yearlyUpdateId.projected, DollarType.Other),
    nextReport: progressReportItemsCategoryNetSubTotal(progressReportId, yearlyUpdateId.nextReport, DollarType.Other)
  }

  function checkTdeExcessDeficit (tdeProjectedNet:number){
    const roundedNetVal =  Math.round(tdeProjectedNet * 100)/100
    console.log(tdeProjectedNet, roundedNetVal, roundedNetVal !== 0 )
    return roundedNetVal !== 0 
  }

    
    return (
      <Box
        component="form"
        sx={{
          // "& .MuiTextField-root": { m: 1, width: "50ch" },
          mx: "auto", 
          width: "90vw",
          
        }}
        noValidate
        autoComplete="off"
      >
        <Typography variant="h1" sx={{fontSize:"4ch", fontWeight: 400}}>Submit your progress report budget</Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell />
              {currentAndFutureYearlyCats 
                .map((el, index) => {
                  return (
                    <TableCell key={el.categoryId}>
                      <Typography variant="h4" component="h4" align="center" color="primary" sx={{ paddingTop: "30px", paddingBottom: "30px" }}>
                        {el.categoryName}
                      </Typography>
                    </TableCell>
                  )
                })}
            </TableRow>
          </TableHead>
          <TableBody>
            <RevenueRows totalsRowData={revenueTotalsRowData} dukeRowData={revenueDukeRowData} otherRowData={revenueOtherRowData}  carryforwardRowData={revenueCarryforwardRowData}/>
            <ExpenseRows totalsRowData={expensesTotalsRowData} dukeRowData={expensesDukeRowData} otherRowData={expensesOtherRowData} />
            <NetDeficitRows totalsRowData={netTotalsRowData} dukeRowData={netDukeRowData} otherRowData={netOtherRowData}/>
          </TableBody>
        </Table>
        { checkTdeExcessDeficit(netDukeRowData.projected) &&
            <Alert sx={{mt: "1ch"}} severity="warning">There is a projected surplus or deficit of Duke Endowment Funds. Please review your budget. If this is accurate, please note the reason in the narrative on the previous page or coordinate with your Endowment contact person using the <strong>Contact Us</strong> button on your progress report form.</Alert>
        }
      </Box>
    );
  }

const RevenueRows = (props:{totalsRowData: submitReportingCategories, dukeRowData:submitReportingCategories, otherRowData: submitReportingCategories, carryforwardRowData: submitReportingCategories}) => {
  const {totalsRowData, dukeRowData, otherRowData, carryforwardRowData} = props;
  
  return (
    <>
      <TableRowCat> {/*//REVENUE PRIMARY ROW */}
        <TableCell>
          <Typography
            variant="h5"
            color="primary"
            sx={{ fontWeight: "bold" }}
          >
            Total Revenues:
          </Typography>
        </TableCell>
        {
          Object.keys(totalsRowData).map((el, index) => {
            return (
              <TableCell align="right" key={index}>
                <Typography
                  variant="h5"
                  color="primary"
                  sx={{ fontWeight: "bold" }}
                >
                  {formatDollarValues(totalsRowData[el])}
                </Typography>
              </TableCell>
            )
          })
        }
        </TableRowCat>
        <TableRow>
          <TableCell><Typography variant="h6" color="primary" >The Duke Endowment</Typography></TableCell>
          {createTableCells(dukeRowData.actual, "h6")}
          {createTableCells(dukeRowData.projected, "h6")}
          {createTableCells(dukeRowData.nextReport, "h6")}
        </TableRow>
        <TableRow>
          <TableCell><Typography variant="h6" color="primary" >Other Sources</Typography></TableCell>
          {createTableCells(otherRowData.actual, "h6")}
          {createTableCells(otherRowData.projected, "h6")}
          {createTableCells(otherRowData.nextReport, "h6")}
        </TableRow>
        <TableRow>
          <TableCell ><Typography variant="h6" color="primary" >Carryforward (net dollar excess)</Typography></TableCell>
          {createTableCells(carryforwardRowData.actual, "h6")}
          {createTableCells(carryforwardRowData.projected, "h6")}
          {createTableCells(carryforwardRowData.nextReport, "h6")}
        </TableRow>
    </>
  )
}

const ExpenseRows = (props:{totalsRowData: submitReportingCategories, dukeRowData:submitReportingCategories, otherRowData: submitReportingCategories}) => {
  const {totalsRowData, dukeRowData, otherRowData} = props;

  return (
    <>
      <TableRowCat>
        <TableCell>
          <Typography
            variant="h5"
            color="primary"
            sx={{ fontWeight: "bold" }}
          >
            Total Expenses:
          </Typography>
        </TableCell>
        {createTableCells(totalsRowData.actual, "h5", { fontWeight: "bold" })}
        {createTableCells(totalsRowData.projected, "h5", { fontWeight: "bold" })}
        {createTableCells(totalsRowData.nextReport, "h5", { fontWeight: "bold" })}
      </TableRowCat>
      <TableRow>
        <TableCell><Typography variant="h6" color="primary" >The Duke Endowment</Typography></TableCell>
        {createTableCells(dukeRowData.actual, "h6")}
        {createTableCells(dukeRowData.projected, "h6")}
        {createTableCells(dukeRowData.nextReport, "h6")}
      </TableRow>
      <TableRow>
          <TableCell><Typography variant="h6" color="primary" >Other Sources</Typography></TableCell>
          {createTableCells(otherRowData.actual, "h6")}
          {createTableCells(otherRowData.projected, "h6")}
          {createTableCells(otherRowData.nextReport, "h6")}
        </TableRow>
    </>
  )
}

const NetDeficitRows = (props: {totalsRowData: submitReportingCategories, dukeRowData:submitReportingCategories, otherRowData: submitReportingCategories}) => {
  const { totalsRowData, dukeRowData, otherRowData } = props;
  // const netTotal = progressReportItemsCategoryNetTotal2(progressReportId, el.categoryId)

  return (
    <>
    <TableRowCat>
      <TableCell>
        <Typography
          variant="h5"
          color="primary"
          sx={{ fontWeight: "bold" }}
        >
          Net Dollar Excess (deficit):
        </Typography>
      </TableCell>
      {
        Object.keys(totalsRowData).map((el, index) => {
          return (
            <TableCell align="right" key={index}>
              <Typography
                variant="h5"
                color="primary"
                sx={{ fontWeight: "bold" }}
              >
                {formatDollarValues(totalsRowData[el])}
              </Typography>
            </TableCell>
          )
        })
      }
      </TableRowCat>
      <TableRow>
        <TableCell><Typography variant="h6" color="primary" >The Duke Endowment</Typography></TableCell>
        {createTableCells(dukeRowData.actual, "h6")}
        {createTableCells(dukeRowData.projected, "h6")}
        {createTableCells(dukeRowData.nextReport, "h6")}
      </TableRow>
      <TableRow>
        <TableCell><Typography variant="h6" color="primary" >Other Sources</Typography></TableCell>
        {createTableCells(otherRowData.actual, "h6")}
        {createTableCells(otherRowData.projected, "h6")}
        {createTableCells(otherRowData.nextReport, "h6")}
      </TableRow>
  </>
  )
}


function createTableCells(dollarVal, variant, cellStyle = {}) {
  return (
    <TableCell>
      <Typography
        variant={variant}
        color="primary"
        textAlign={'end'}
        sx={cellStyle}
      >
        {formatDollarValues(dollarVal)}
      </Typography>
    </TableCell>
  )
}


