import * as React from "react";
import IconButton from "@mui/material/IconButton";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TextField from '@mui/material/TextField';
import Typography from "@mui/material/Typography";
import AutoComplete from "@mui/material/Autocomplete";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import Tooltip from "@mui/material/Tooltip";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import RemoveCircleOutlinedIcon from "@mui/icons-material/RemoveCircleOutlined";
import {
  DollarType,
  BudgetID,
} from "../../../../app/models";
import {
  setDefinitionHelpers,
  selectPrintScreenOpen
} from '../../../Common/stateSlices/appStateSlice';
import {
  addFinalReportBudgetItemsLine,
  setFinalReportRowName,
  selectBudgetItemsSubTotal,
  selectFinalReportItemOverUnderValue,
  selectFinalReportItemValue,
  setFinalItemValue,
  selectFinalReportCategoryItems,
  selectFinalReportItemName,
  selectFinalReportCategorySubTotal,
  selectFinalReportCategoryTotal,
  selectBudgetItemsCategorySubTotal,
  selectBudgetItemsCategoryTotal,
  selectFinalReportCategoryOverUnderValueSubTotal
} from '../../../Common/stateSlices/budgetStateSlice';
import{
  selectAllPaymentsAmount,
} from "../../../Common/stateSlices/bbgDataSlice"
import { useAppSelector, useAppDispatch } from "../../../../app/hooks";
import { formatDollarValues, handleDollarInput } from "../../../Common/helpers";
import { TableCellBorderL, TableCellBorderR, TableCellBorderLR } from "../../../Common/ThemedMuiComponents";

function createBudgetCategory(
  id: string = "",
  name: string = "",
  groupName: string = "",
  maxItems: number = 10
) {
  return {
    id,
    name,
    groupName,
    maxItems
  };
}

function ItemRow(props: {
  nameEditable: boolean; 
  rowIndex: number; 
  category: string; 
  row: ReturnType<typeof createBudgetCategory>; 
  insideRow: {reqdLine?: boolean, lineName?: string, carryforward?: boolean};
  finalReportId: number;
  disabled: boolean;
}){
  const { row, rowIndex, insideRow, disabled} = props;
  const dispatch = useAppDispatch();
  const finalReportItemName = useAppSelector(selectFinalReportItemName);
  const finalReportItemValue = useAppSelector(selectFinalReportItemValue);
  const finalReportItemOverUnderValue = useAppSelector(selectFinalReportItemOverUnderValue)
  const fullAmount = useAppSelector(selectAllPaymentsAmount);
  const printScreenOpen = useAppSelector(selectPrintScreenOpen)

  const budgetItemsSubTotal = useAppSelector(selectBudgetItemsSubTotal)
  const deleteRow = (index: number) => {
    // dispatch(removeBudgetItemsForYear({finalReportId, category: row.id, lineIndex: index }));
  };
  
  return(
    <TableRow
      sx={{
        "& > *": { borderBottom: "unset" },
      }}>
{/* delete icon button or required icon */}
      <TableCell sx={{ minWidth: "4ch", padding: 0 }}>
        {insideRow.reqdLine || disabled || printScreenOpen?  (
          null
        ) : (
          <Tooltip title="Delete row">
            <IconButton onClick={() => deleteRow(rowIndex)}>
              <RemoveCircleOutlinedIcon />
            </IconButton>
          </Tooltip>
        )}
      </TableCell>

      <TableCell component="th" scope="row" sx={{ minWidth: "35ch", paddingLeft: "20px" }}>
        {(insideRow.reqdLine || disabled || printScreenOpen)  ? (
          <Typography>{insideRow.lineName}</Typography>
        ) : (
// input box for enter expenses
          <FormControl fullWidth size="small">
            {
            (row.id === 'programExpense' || row.id === 'capitalExpense')
              ? <AutoComplete
                id="outlined-adornment-amount"
                spellCheck={true}
                freeSolo
                placeholder={`Enter ${row.name}...`}
                options={[]}
                renderInput={(params) => <TextField {...params} label={row.groupName} />}
                onInputChange={(event: React.ChangeEvent<HTMLInputElement>, newInputVal) => {
                  dispatch(setFinalReportRowName({category: row.id, lineIndex: rowIndex, lineName: newInputVal }))
                }}
                // value={finalReportItemName( row.id, rowIndex)}
                value = {insideRow.lineName}
                disabled = {disabled}
              />
              :
              <OutlinedInput
// Might use in the future
                id="outlined-adornment-amount"
                spellCheck={true}
                placeholder={`Enter ${row.name}...`}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  dispatch(setFinalReportRowName({category: row.id, lineIndex: rowIndex, lineName: event.target.value }))
                }}
                  value={finalReportItemName(row.id, rowIndex)}
                disabled = {disabled}
              />
            }
            
          </FormControl>
        )}
      </TableCell>
  {/* Total Project Budget Values */}
      <TableCellBorderL align="right">
        {formatDollarValues(budgetItemsSubTotal(row.id, DollarType.TDE, rowIndex))}
      </TableCellBorderL>
      <TableCellBorderR align="right">
        {formatDollarValues(budgetItemsSubTotal(row.id, DollarType.Other, rowIndex))}
      </TableCellBorderR>
  {/* Total Project Actual Values */}
      <TableCellBorderL align="right">
        {
          row.id===BudgetID.revenue && rowIndex === 0
            ? <Typography>
                {formatDollarValues(fullAmount)}
              </Typography>
            : null
        }
        {
        row.id === BudgetID.revenue || printScreenOpen
          ? null
          : <OutlinedInput
              id="outlined-adornment-amount"
              sx={{input: { textAlign: 'right' } }}
              disabled={disabled}
              placeholder={"--"}
              startAdornment={
                <InputAdornment position="start">
                  $
                </InputAdornment>
              }
              value={(finalReportItemValue(row.id, rowIndex, DollarType.TDE) || "").toLocaleString()}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                dispatch(setFinalItemValue({ category: row.id, index: rowIndex, amount: handleDollarInput(event.target.value), typeDollars: DollarType.TDE }));
              }}
            />
        }
        {
          row.id !== BudgetID.revenue && printScreenOpen
          ? <Typography>
              {formatDollarValues(finalReportItemValue(row.id, rowIndex, DollarType.TDE))}
            </Typography>
          :null
        }

      </TableCellBorderL>
      <TableCellBorderR >
        {
          !(row.id === BudgetID.revenue && rowIndex === 0) && !printScreenOpen // remove from Revenue: The Duke Endowment
            ? <OutlinedInput
              id="outlined-adornment-amount"
              sx={{input: { textAlign: 'right' } }}
              disabled={disabled}
              placeholder={"--"}
              startAdornment={
                <InputAdornment position="start">
                  $
                </InputAdornment>
              }
              value={(finalReportItemValue(row.id, rowIndex, DollarType.Other) || "").toLocaleString()}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                dispatch(setFinalItemValue({ category: row.id, index: rowIndex, amount: handleDollarInput(event.target.value), typeDollars: DollarType.Other }));
              }}
            />
            : null
        }
        {!(row.id === BudgetID.revenue && rowIndex === 0) && printScreenOpen 
          ? <Typography align="right">
              {formatDollarValues(finalReportItemValue(row.id, rowIndex, DollarType.Other))}
            </Typography>
          :null
          }

      </TableCellBorderR >
  {/* Over/Under Col */}
      <TableCellBorderL align="right">
        {formatDollarValues(finalReportItemOverUnderValue(row.id, rowIndex, DollarType.TDE))}
      </TableCellBorderL>
      <TableCellBorderR align="right">
        {formatDollarValues(finalReportItemOverUnderValue(row.id, rowIndex, DollarType.Other))}
      </TableCellBorderR>

    </TableRow>
  )
}

export function FinalReportCategoryRow(props: {
  row: ReturnType<typeof createBudgetCategory>
  finalReportId: number,
  disabled: boolean
}) {
  const { row, finalReportId, disabled} = props;
  const [open, setOpen] = React.useState(true);
  const finalReportCategoryItemsSelector = useAppSelector(selectFinalReportCategoryItems);
  const finalReportCategoryItems: [] = finalReportCategoryItemsSelector(row.id);
  const budgetItemsCategorySubTotal = useAppSelector(selectBudgetItemsCategorySubTotal)
  const budgetItemsCategoryTotal = useAppSelector(selectBudgetItemsCategoryTotal)
  const finalReportCategorySubTotal = useAppSelector(selectFinalReportCategorySubTotal);
  const finalReportCategoryTotal = useAppSelector(selectFinalReportCategoryTotal);
  const finalReportCategoryOverUnderValueSubTotal = useAppSelector(selectFinalReportCategoryOverUnderValueSubTotal)
  const printScreenOpen = useAppSelector(selectPrintScreenOpen)

  const dispatch = useAppDispatch();
  const addRow = () => {
     dispatch(addFinalReportBudgetItemsLine({ category: row.id}))
  };
  const handleDefClickOpen = (helperKey: string) => {
    dispatch(setDefinitionHelpers({ visible: true, key: helperKey }))
  }

  const overUnderSubTotals = {
    tde: finalReportCategoryOverUnderValueSubTotal(row.id, DollarType.TDE), 
    other: finalReportCategoryOverUnderValueSubTotal(row.id, DollarType.Other)
  }
  const overUnderTotal = overUnderSubTotals.tde + overUnderSubTotals.other
  


  return (
    <React.Fragment>
      <TableRow 
        sx={{
           "& > *": { borderBottom: "unset" },
          backgroundColor: "rgba(117, 106, 78,.1)",
          borderTop: "2px solid rgba(0,0,0,.3)",
          borderBottom: "2px solid rgba(0,0,0,.3)"
      }}>
{/* collapse icon or uncollapse icon in the beginning */}
        <TableCell sx={{ width: "5ch", padding: 0 }}>
          {!printScreenOpen &&           
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          }
        </TableCell>
{/* Header for Total Amount*/}
{/* REVENUE, DIRECT EXPENSES AND INDIRECT EXPENSES */}
        <TableCell
          component="th"
          scope="row"
          sx={{
            width: "38ch",
            fontWeight: "bold",
            textTransform: "uppercase",
          }}
        >
          <Typography
            variant="subtitle1"
            gutterBottom
            component="div"
            sx={{ fontWeight: "bold", fontSize: "1.1rem" }}
          >
            {row.groupName}
            {!printScreenOpen && 
            <IconButton
              size="small"
              sx={{ position: 'relative', top: '-8px', padding: 0 }}
              id="test"
              onClick={() => handleDefClickOpen(row.id)}
            >
              <InfoOutlinedIcon fontSize="small" />
            </IconButton>
            }  
          </Typography>
        </TableCell>
        <TableCellBorderLR sx={{ fontWeight: "bold", fontSize: "1rem"}} colSpan={2} align="center">
          {
            formatDollarValues(budgetItemsCategoryTotal(row.id))
          }
        </TableCellBorderLR>
        <TableCellBorderLR sx={{ fontWeight: "bold", fontSize: "1rem" }} colSpan={2} align="center">
          {
            formatDollarValues(finalReportCategoryTotal(row.id))
          }
        </TableCellBorderLR>
        <TableCellBorderLR sx={{ fontWeight: "bold", fontSize: "1rem" }} colSpan={2} align="center">
          {
            formatDollarValues(overUnderTotal)
          }
        </TableCellBorderLR>
      </TableRow>
{/* Item rows */}
      {open && 
        finalReportCategoryItems.map( (item, rowIndex) => {
          return (
            <ItemRow
              key = {row.id+rowIndex}
              category={row.id}
              nameEditable={true}
              rowIndex={rowIndex}
              row={row}
              insideRow={item}
              finalReportId={finalReportId}
              disabled={disabled}
            />
          )
        })
      }
      {open &&  (  //TODO
      <>
        {!printScreenOpen && 
          <TableRow sx={{
            borderBottom: "1px solid rgba(0,0,0,.1)"
          }}>
            <TableCell sx={{ borderBottom: "0px solid black" }} />
  {/* ADD new Expense Button Or Alert for the Max Line  */}
            <TableCell sx={{ borderBottom: "0px solid black", paddingY: "5px"}} >
              < Button
                variant="outlined"
                startIcon={<AddIcon />}
                onClick={addRow}
                >
                Add {row.name}
              </Button>
            </TableCell>
  {/* Blank spacing after Add button */}
              {["budget", "actual", "overUnder"].map((el, index) => {
                return (
                  <React.Fragment key={el}>
                    <>
                      <TableCellBorderL align="right" sx={{ padding: 1 }} colSpan={2}>
                      </TableCellBorderL>
                    </>
                  </React.Fragment>
                );
              })}
          </TableRow>
        }
{/* SubTotals Row */}
        <TableRow sx={{ 
          backgroundColor: "rgba(117, 106, 78,.03)", 
            height: "20px",
          }}>
          <TableCell />
          <TableCell sx={{ padding: "8px" }} >
            <Typography>Sub-Totals for {row.name}</Typography>
          </TableCell>
      {/* Sub-Totals Total Project Budget */}
          <TableCellBorderL sx={{ paddingY: "8px"}} align="right">
              {formatDollarValues(budgetItemsCategorySubTotal(row.id, DollarType.TDE))}
          </TableCellBorderL>
          <TableCellBorderR sx={{ paddingY: "8px"}} align="right">
              {formatDollarValues(budgetItemsCategorySubTotal(row.id, DollarType.Other))}
          </TableCellBorderR>
      {/* Sub-Totals Total Project Actual */}
          <TableCellBorderL sx={{ paddingY: "8px" }} align="right">
              {formatDollarValues(finalReportCategorySubTotal(row.id, DollarType.TDE))}
          </TableCellBorderL>
          <TableCellBorderR sx={{ paddingY: "8px" }} align="right">
              {formatDollarValues(finalReportCategorySubTotal(row.id, DollarType.Other))}
          </TableCellBorderR>
      {/* Sub-Totals Actual Over/Under */}
          <TableCellBorderL sx={{ paddingY: "8px"}} align="right">
              {
                formatDollarValues( overUnderSubTotals.tde)
              }
          </TableCellBorderL>
          <TableCellBorderR sx={{ paddingY: "8px" }} align="right">
              {
                formatDollarValues(overUnderSubTotals.other)
              }
          </TableCellBorderR>
        </TableRow>
{/* Totals Row */}
        <TableRow sx={{ 
          backgroundColor: "rgba(117, 106, 78,.03)", 
            height: "20px",
            borderBottom: "2px solid rgba(0,0,0,.3)"
          }}>
          <TableCell />
          <TableCell sx={{ padding: "8px" }} >
            <Typography>Totals for {row.name}</Typography>
          </TableCell>
            <TableCellBorderL sx={{ padding: "8px" }} colSpan={2} align="center">
              <Typography>
                {formatDollarValues(budgetItemsCategoryTotal(row.id))}
              </Typography>
            </TableCellBorderL>
            <TableCellBorderL sx={{ padding: "8px" }} colSpan={2} align="center">
              <Typography>
                {formatDollarValues(finalReportCategoryTotal(row.id))}
              </Typography>
            </TableCellBorderL>
            <TableCellBorderLR sx={{ padding: "8px" }} colSpan={2} align="center">
              <Typography>
                {formatDollarValues(overUnderTotal)}
              </Typography>
            </TableCellBorderLR>
        </TableRow>
      </>
      )}
      { row.id === BudgetID.revenue &&
        <TableRow >
          <TableCell sx={{padding: "5px"}}/>
        </TableRow> 
      }
    </React.Fragment>
  );
}
