import * as React from "react";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useAppSelector, useAppDispatch } from "../../../../app/hooks";
import { FinalReportCategoryRow } from "./FinalReportCategoryRow";
import {
  BudgetID,
  AvailableBudgetTypes,
} from "../../../../app/models";
import {
  setDefinitionHelpers,
  setPrintScreenOpen,
  selectPrintScreenOpen
} from '../../../Common/stateSlices/appStateSlice';
import {
  selectExpenseTypesAvailable,
  selectBudgetItemsCategoryTotal,
  selectFinalReportCategoryTotal, 
  selectFinalReportId
} from '../../../Common/stateSlices/budgetStateSlice';
import { selectBlackbaudRequestRef, selectFinalReportDates } from "../../../Common/stateSlices/bbgDataSlice";
import { Button } from "@mui/material";
import  Print  from "@mui/icons-material/Print";
import { handleDate } from "../../../Common/helpers";

import { TableCellBorderLR, TableCellBorderL, TableCellBorderR } from "../../../Common/ThemedMuiComponents";
import { formatDollarValues } from "../../../Common/helpers";
import { start } from "repl";


export const FinalReportTable = (props: {disabled: boolean}) => {
  const {disabled} = props;
  const { hasProgramExpenses, hasIndirectExpenses } = useAppSelector(selectExpenseTypesAvailable)
  const budgetCategories = AvailableBudgetTypes({ hasProgramExpenses, hasIndirectExpenses, hasInKindExpenses: false })
  const finalReportId = useAppSelector(selectFinalReportId)
  const budgetItemsCategoryTotal = useAppSelector(selectBudgetItemsCategoryTotal)
  const finalReportCategoryTotal = useAppSelector(selectFinalReportCategoryTotal);
  const printScreenOpen = useAppSelector(selectPrintScreenOpen);
  const blackbaudRequestRef = useAppSelector(selectBlackbaudRequestRef);
  const finalReportDates = useAppSelector(selectFinalReportDates);
  const {projectStartDate, projectEndDate} = finalReportDates

  const startDate = projectStartDate// handleDate(projectStartDate);
  const endDate = projectEndDate //handleDate(projectEndDate);


  const dispatch = useAppDispatch();
  const handleDefClickOpen = (helperKey: string = "giftInKind") => dispatch(setDefinitionHelpers({ visible: true, key: helperKey }))

  function handlePrintScreenOpen(){
    dispatch(setPrintScreenOpen({id: null, reqType: "Final Report" }))
  }

  return (
    <TableContainer sx={{ mx: "auto", width: "calc(100vw*.9)", display: 'flex', flexDirection: 'column'  }}>
      { !printScreenOpen &&     
        <>
        <Button
          endIcon={<Print />}
          variant="text"
          onClick={handlePrintScreenOpen}
          sx={{alignSelf: "end", width: "170px"}}
        >
          Print PDF
        </Button><br/>
        </>
      }
      <Table aria-label="collapsible table">
        <TableHead>
{/* 1st Row Current Budget to Next Year's Budget */}
          <TableRow>
            <TableCell style={{ borderBottom: "0px solid" }} />
            <TableCell
              sx={{ borderBottom: "0px solid", width: "35ch" }}
            ></TableCell>
            <TableCellBorderLR  // total Project Budget header
              sx={{
                borderBottom: "0px solid",
                fontWeight: "bold",
                textTransform: "uppercase",
                fontSize: "1.1rem",
                paddingY: "0px"
              }}
              align="center"
              colSpan={2}
            >
              Total Project Budget
              { !printScreenOpen && 
                <IconButton
                size="small"
                sx={{ position: 'relative', top: '-8px', padding: 0 }}
                onClick={() => {
                  handleDefClickOpen("totalProjectBudget")
                }}
              >
                <InfoOutlinedIcon fontSize="small" />
              </IconButton>
              }
              <br/>
              <Typography sx={{textTransform:"none"}} variant="subtitle1" fontSize="small">
              {startDate} - {endDate}
              </Typography>
            </TableCellBorderLR>
            <TableCellBorderLR // total Project Actual header
              sx={{
                borderBottom: "0px solid",
                fontWeight: "bold",
                textTransform: "uppercase",
                fontSize: "1.1rem",
                paddingY: "0px"
              }}
              align="center"
              colSpan={2}
            >
              Total Project Actual
              {!printScreenOpen && 
                <IconButton
                  size="small"
                  sx={{ position: 'relative', top: '-8px', padding: 0 }}
                  onClick={() => {
                    handleDefClickOpen("totalProjectActual")
                  }}
                >
                  <InfoOutlinedIcon fontSize="small" />
                </IconButton>
              }
              <br/>
              <Typography sx={{textTransform:"none"}} variant="subtitle1" fontSize="small">
                {startDate} - {endDate}
              </Typography>
            </TableCellBorderLR>
            <TableCellBorderLR // actual over/under header
              sx={{
                borderBottom: "0px solid",
                fontWeight: "bold",
                textTransform: "uppercase",
                fontSize: "1.1rem",
                paddingY: "0px"
              }}
              align="center"
              colSpan={2}
            >
              Actual(Over)/Under Budget
              {!printScreenOpen && 
                <IconButton
                  size="small"
                  sx={{ position: 'relative', top: '-8px', padding: 0 }}
                  onClick={() => {
                    handleDefClickOpen("projectActualOverUnder")
                  }}
                >
                  <InfoOutlinedIcon fontSize="small" />
                </IconButton>
              }
              <br/>
              <Typography sx={{textTransform:"none", visibility:"hidden"}} variant="subtitle1" fontSize="small">
              -
              </Typography>
            </TableCellBorderLR>
          </TableRow>
{/* 2nd Row: TDE & Other */}
          <TableRow>
            <TableCell />
            <TableCell sx={{ width: "35ch" }} />
            {["budget", "actual", "overUnder"].map((el, index) => {
              return (
                <React.Fragment key={el}>
                  <TableCellBorderL 
                    key={el + "TDE"} 
                    align="center"
                    sx={{paddingY: '1ch'}}
                    >
                    <Typography 
                      variant="subtitle1" 
                      sx={{ 
                        fontWeight: "bold", 
                        display: "inline-flex", 
                        verticalAlign: "middle"
                      }}
                    >
                      TDE
                      {
                        index === 0 && !printScreenOpen
                          ? <IconButton
                            size="small"
                            sx={{ position: 'relative', padding: 0, paddingLeft: '3px' }}
                            id="test"
                            onClick={() => handleDefClickOpen(' ')}
                          >
                            <InfoOutlinedIcon fontSize="small" />
                          </IconButton>
                          : null
                      }
                    </Typography>
                  </TableCellBorderL>
                  <TableCellBorderR key={index + "Other"} align="center" sx={{ paddingY: '0.7ch' }}>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        fontWeight: "bold",
                        display: "inline-flex",
                        verticalAlign: "middle"
                      }}
                    >
                      Other
                      {
                        index === 0 && !printScreenOpen
                          ? <IconButton
                            size="small"
                            sx={{ position: 'relative', paddingLeft: '3px'}}
                            id="test"
                            onClick={() => handleDefClickOpen('OtherRevenueExpense')}
                          >
                            <InfoOutlinedIcon fontSize="small" />
                          </IconButton>
                          : null
                      }
                    </Typography>
                  </TableCellBorderR>
                </React.Fragment>
              );
            })}

          </TableRow>
        </TableHead>
        <TableBody>
{/* table items */}
          {budgetCategories
            .map((row) => (
              <FinalReportCategoryRow
                key={row.id}
                row={row}
                finalReportId={finalReportId}
                disabled={disabled}
              />
            ))}
          <TableRow >
            <TableCell sx={{ padding: "5px" }} />
          </TableRow> 
{/* Revenue Totals Row */}
          <TableRow sx={{ backgroundColor: "rgba(117, 106, 78,.05)", borderTop: "2px solid rgba(0,0,0,.3)" }}>
            <TableCell style={{ borderBottom: "0px solid" }} />
            <TableCell
              style={{ borderBottom: "0px solid", fontSize: ".95rem"}}
              sx={{ width: "35ch" }}
            >
              Total Revenue
            </TableCell>
            <TableCellBorderLR
              style={{ borderBottom: "0px solid", fontStyle: "italic" }}
              align="center"
              colSpan={2}
            >
              {formatDollarValues(budgetItemsCategoryTotal(BudgetID.revenue))}
            </TableCellBorderLR>
            <TableCellBorderLR
              style={{ borderBottom: "0px solid", fontStyle: "italic" }}
              align="center"
              colSpan={2}
            >
              {formatDollarValues(finalReportCategoryTotal(BudgetID.revenue))}
            </TableCellBorderLR>
            <TableCellBorderLR
              style={{ borderBottom: "0px solid", fontStyle: "italic" }}
              align="center"
              colSpan={2}
            >
              {formatDollarValues(
                finalReportCategoryTotal(BudgetID.revenue) - budgetItemsCategoryTotal(BudgetID.revenue)
                )
              }
            </TableCellBorderLR>
          </TableRow>
{/* Total Expenses Row */}
          <TableRow sx={{ backgroundColor: "rgba(117, 106, 78,.05)" }}>
            <TableCell style={{ borderBottom: "0px solid" }} />
            <TableCell
              style={{ borderBottom: "0px solid", fontSize:".95rem" }}
              sx={{ width: "35ch" }}
            >
              Total Expenses
            </TableCell>
            <TableCellBorderLR
              style={{ borderBottom: "0px solid", fontStyle: "italic" }}
              align="center"
              colSpan={2}
            >
              {formatDollarValues(budgetItemsCategoryTotal(BudgetID.indirectExpense)+budgetItemsCategoryTotal(BudgetID.programExpense))}
            </TableCellBorderLR>
            <TableCellBorderLR
              style={{ borderBottom: "0px solid", fontStyle: "italic" }}
              align="center"
              colSpan={2}
            >
              {
              formatDollarValues(
                finalReportCategoryTotal(BudgetID.programExpense) +
                finalReportCategoryTotal(BudgetID.indirectExpense)
              )
              }
            </TableCellBorderLR>
            <TableCellBorderLR
              style={{ borderBottom: "0px solid", fontStyle: "italic" }}
              align="center"
              colSpan={2}
            >
              {formatDollarValues(
                finalReportCategoryTotal(BudgetID.programExpense) +
                finalReportCategoryTotal(BudgetID.indirectExpense) -
                budgetItemsCategoryTotal(BudgetID.indirectExpense) -
                budgetItemsCategoryTotal(BudgetID.programExpense)
              )
              }
            </TableCellBorderLR>
          </TableRow>
{/* Net Dollar Excess (Deficit) Row */}
          <TableRow sx={{ backgroundColor: "rgba(117, 106, 78,.05)" }} style={{ border: "0px solid", borderBottom: "2px solid rgba(0,0,0,.3)" }}>
              <TableCell sx={{ borderBottom: "0px solid" }} />
              <TableCell
                sx={{
                  borderBottom: "0px solid",
                  width: "35ch"
                }}
              >
                Net Dollar Excess (Deficit)
              </TableCell>
              <TableCellBorderLR
                style={{ borderBottom: "0px solid", fontStyle: "italic" }}
                align="center"
                colSpan={2}
              >
                {formatDollarValues(
                  budgetItemsCategoryTotal(BudgetID.revenue) -
                  budgetItemsCategoryTotal(BudgetID.indirectExpense) -
                  budgetItemsCategoryTotal(BudgetID.programExpense)
                  )
                  }
              </TableCellBorderLR>
              <TableCellBorderLR
                style={{ borderBottom: "0px solid", fontStyle: "italic" }}
                align="center"
                colSpan={2}
              >
              {
                formatDollarValues(
                  finalReportCategoryTotal(BudgetID.revenue)-
                  finalReportCategoryTotal(BudgetID.programExpense)-
                  finalReportCategoryTotal(BudgetID.indirectExpense)
              )
              }
              </TableCellBorderLR>
              <TableCellBorderLR
                style={{ borderBottom: "0px solid", fontStyle: "italic" }}
                align="center"
                colSpan={2}
              >
                {
                  formatDollarValues(
                    (
                      finalReportCategoryTotal(BudgetID.revenue) -
                      finalReportCategoryTotal(BudgetID.programExpense) -
                      finalReportCategoryTotal(BudgetID.indirectExpense)
                    ) -
                    (
                      budgetItemsCategoryTotal(BudgetID.revenue) -
                      budgetItemsCategoryTotal(BudgetID.indirectExpense) -
                      budgetItemsCategoryTotal(BudgetID.programExpense)
                    )
                  )
                }
              </TableCellBorderLR>
            </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};


